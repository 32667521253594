.ant-select-show-search:where(.css-dev-only-do-not-override-1vtf12y).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  color: #10284b;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 35px;
  /* border-radius: 15px !important; */
  margin-bottom: 12px !important;
  border: 2px solid #d0d0d0 !important;
  background: #f8f8f8;
  height: 50px;
}

:where(.css-dev-only-do-not-override-1vtf12y).ant-select .ant-select-arrow {
  color: black !important;
}

.row-flex-contain {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.btn-common {
  background-color: #FFD700;
  color: #13595A;
  font-style: normal;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 10px;
}

/*form*/
.form-control {
  background-color: #fff;
  color: #10284b;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 35px;
  /* border-radius: 15px !important; */
  margin-bottom: 12px !important;
  border: 2px solid #d0d0d0 !important;
  background: #f8f8f8;
  height: 50px;
}

.form-control::placeholder,
.form-controlt::-ms-input-placeholder {
  color: #fefefe;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: rgb(16 40 75 / 15%);
}

.card-custom {
  background-color: #6c757d;
}

/*form*/
.glow {
  animation: glow-effect 1.0s ease-out;
}

@keyframes glow-effect {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.7);
  }

  100% {
    box-shadow: 0 0 0 10px rgba(0, 255, 0, 0);
  }
}

/* form-select  */
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: grey !important;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  /* border-radius: 0.375rem; */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  border-radius: 5px !important;
  margin-bottom: 12px !important;
  border: 2px solid #d0d0d0 !important;
  height: 50px !important;
}

.active-status {
  margin-left: 10px;
}

.upload-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.question-style {
  font-size: 20px;
}

.option-style {
  font-size: 18px;
}

.answer-style {
  font-size: 18;
}

/* package */
.data-list {
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  word-spacing: 1.5px;
}

.content-data-heading {
  font-size: 22px;
  margin: 10px 0px;
  font-weight: 500;
  background: grey;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.container-content-list {
  padding: 10px;
  background: #efefef;
}

.container-content-list hr {
  margin: 5px;
}

.mcq-type-for {
  margin-right: 2rem;
}

.logout-btn {
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 4px 10px;
  font-size: 13px;
}